import React from 'react'

const Statics_box = ({ h_value, backgroundImage, content, count }) => {
    const divStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        padding: '40px 50px',
        // height: '250px' // Example height, adjust as needed
    };
    return (
        <div>

            <div className='stat_box text-light' style={divStyle} >
                <span className='count_number ' style={{ fontSize: '42px', fontWeight: '800', marginBottom: '10px' }}>{count}</span>
                <h5 className='mb-2 mt-2' style={{ fontWeight: '800' }}>{h_value}</h5>
                <p style={{ color: '#fff', lineHeight: '32px' }}>{content}</p>
            </div>
        </div>
    )
}

export default Statics_box
