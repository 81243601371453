import React from 'react'
import { Link } from 'react-router-dom'

const TeamBox = ({ image, name, destination }) => {
    return (
        <div className='team_member'>
            <div className='member_image'>
                <img style={{ maxWidth: '100%' }} src={image} alt={destination}></img>
                <div className='member_social_links'>
                    <span className='member_social_link'>
                        <Link to='/'>
                            <i class="fa-brands fa-linkedin-in"></i>
                        </Link>
                    </span> <span className='member_social_link'>
                        <Link to='/'>
                            <i class="fa-brands fa-instagram"></i>
                        </Link>
                    </span><span className='member_social_link'>
                        <Link to='/'>
                            <i class="fa-brands fa-facebook-f"></i>
                        </Link>
                    </span><span className='member_social_link'><Link to='/'>
                        <i class="fa-brands fa-twitter"></i>
                    </Link>
                    </span>
                </div>
            </div>
            <div className='member_info py-3'>
                <h5 className='member_name'>{name}</h5>
                <span className='member_rank'>{destination}</span>
            </div>
        </div>
    )
}

export default TeamBox
