import React from 'react'

const Clientbox = ({ cImage, name, rank, content }) => {
    return (
        <div>
            <div className='client_box border'>
                <div className='client_box_head d-flex mb-4 align-items-center'>
                    <div className='client_pic me-4'><img src={cImage} alt='Client image' /></div>
                    <div className='client_info'>
                        <h5 className='client_name mb-0'>{name} ,</h5>
                        <p className='client_rank'>{rank}</p>
                    </div>
                </div>
                <div className='client_box_content'>
                    <p>{content}</p>
                </div>
            </div>
        </div>
    )
}

export default Clientbox
