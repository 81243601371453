import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../navbar';
import Heading_up from '../heading_up';
import H_section from '../h_section';
import Footer from '../footer';
import Contactbox from '../subcomponents/contactbox';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: ''
    });

    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const formErrors = {};
        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (!formData.message) formErrors.message = "Message is required";
        if (!formData.phone) formErrors.phone = "Phone no is required";

        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return; // Stop form submission if validation fails
        }

        try {
            // Send form data to the backend
            const response = await fetch('http://localhost:4000/api/extreme/storecontact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                toast.success('Message Sent Successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); setSubmitStatus('success');
                // Clear form
                setFormData({ name: '', email: '', message: '', phone: '' });
                setErrors({});
            } else {
                setSubmitStatus('error');
                alert('Failed to send message. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmitStatus('error');
            alert('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div>
            <Navbar />
            <div className='page_container'>
                <div className='page_info'>
                    <div className='container'>
                        <h2 className='page_name'>Contacts</h2>
                    </div>
                </div>
                <div className='page-content' style={{ marginTop: '80px' }}>
                    <div className='contact_container'>
                        <div className='container'>
                            <div className='contact_main_sec row mb-5'>
                                <div className='col-lg-6'>
                                    <Heading_up value='// Contact details' />
                                    <H_section value='Contact us' />
                                    <p>
                                        Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.
                                    </p>
                                    <div className='mt-4'>
                                        <Contactbox iconClass='fa-solid fa-envelope' title='Our Email' info='contact@extremelogics.co' />
                                        <Contactbox iconClass='fa-solid fa-globe' title='Our Address' info='Gulberg Phase 2, Khanewal, Pakistan' />
                                        <Contactbox iconClass='fa-solid fa-phone' title='Our Phone' info='+92 - 3186769031' />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='contact_us_form'>
                                        <h2>Ready to get Started?</h2>
                                        <p style={{ color: "#fff", fontSize: '14px' }}>Your email address will not be published. Required fields are marked *</p>
                                        <form onSubmit={handleSubmit} className='mt-5'>
                                            <input
                                                type='text'
                                                name='name'
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder='Name *'
                                            />
                                            {errors.name && <p className='error text-danger mb-0 mt-0'>{errors.name}</p>}
                                            <br />
                                            <input
                                                type='number'
                                                name='phone'
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder='Phone no *'
                                            />
                                            {errors.phone && <p className='error text-danger mb-0 mt-0'>{errors.phone}</p>}
                                            <br />
                                            <input
                                                type='email'
                                                name='email'
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder='Email *'
                                            />
                                            {errors.email && <p className='error text-danger mb-0 mt-0'>{errors.email}</p>}
                                            <br />
                                            <textarea
                                                cols='8'
                                                rows='5'
                                                placeholder='Message *'
                                                name='message'
                                                value={formData.message}
                                                onChange={handleChange}
                                            />
                                            {errors.message && <p className='error text-danger mb-0 mt-0'>{errors.message}</p>}
                                            <br />
                                            <button className='main_button' id='contact_form_button' type='submit'>Send Message</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='company_map'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3444.533630242384!2d71.94616387234953!3d30.30733575037699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1722938848502!5m2!1sen!2s"
                                width="100%"
                                height="450"
                                style={{ border: '0' }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

            />
        </div>
    );
};

export default Contact;
