import React from 'react'

const Techbox = ({ heading, iconClass, linkPath }) => {
  return (
    <div>
      <a href={linkPath} className='tech_box'>
        <i className={iconClass}></i>
        <h6 className='tech_box_title'>{heading}</h6>
      </a>
    </div>
  )
}

export default Techbox
