import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import ClientCarousel from './subcomponents/client_carousel'

const Client = () => {
    return (
        <div>
            <div className='client_main container'>
                <div className='client_sec_headings text-center'>
                    <Heading_up value='// OUR CLIENTS' />
                    <div >
                        <H_section value='We are Trusted' value1='20+ Countries Worldwide' />
                    </div>
                </div>
                <div className='client_sec_body mt-5'>
                    <ClientCarousel />
                </div>

            </div>
        </div>
    )
}

export default Client
