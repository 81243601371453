import React from 'react'

const Footerbox = ({ iconClass, title, info }) => {
    return (
        <div className='footer_box text-center'>
            <div className='fb_icon'>
                <i class={iconClass}></i>
            </div>
            <h5 className='fb_info mb-0'>{info}</h5>

            <p className='fb_title mt-3 pb-0 mb-0'>{title}</p>

        </div>
    )
}

export default Footerbox
