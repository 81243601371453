// AdminLogin.js
import React, { useState } from 'react';
import axios from 'axios';

const AdminLogin = ({ onLogin }) => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:4000/api/extreme/login', credentials);
            localStorage.setItem('token', response.data.token);
            onLogin(true);
        } catch (error) {
            setError('Invalid credentials');
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center flex-column mb-5 mt-4'>
            <h1>Admin Login</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit} className='mt-4'>
                <input style={{ width: '100%' }} className='mb-4 py-1 form-control'
                    type='text'
                    name='username'
                    value={credentials.username}
                    onChange={handleChange}
                    placeholder='Username'
                    required
                />
                <br />
                <input style={{ width: '100%' }} className='py-1 form-control'
                    type='password'
                    name='password'
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder='Password'
                    required
                />
                <br />
                <button className='mt-4' type='submit'>Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
