import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import Button from './subcomponents/button'
import Services_box from './subcomponents/services_box'

const Services_main = () => {
    return (
        <div>
            <div>
                <div className='services_up_container d-flex justify-content-between align-items-center'>
                    <div>
                        <Heading_up value='// Our Services' />
                        <H_section value='Offering Comprehensive ' value1='Range of IT Services' />
                    </div>
                    <div>
                        <Button value='All Services' linkPath='/services' />
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <div className='row'>
                    <div className='col-xl-4 col-md-6 mb-5'>
                        <Services_box icon={<i class="fa-solid fa-laptop-code"></i>} heading="Web Development" content='Our extensive experience sets us apart in delivering exceptional web development solutions..' />
                    </div>

                    <div className='col-xl-4 col-md-6 mb-5'>
                        <Services_box icon={<i class="fa-solid fa-mobile-screen"></i>} heading="Mobile Development" content='We build advanced mobile applications, ensure seamless integration, and modernize existing systems.' />
                    </div>
                    <div className='col-xl-4 col-md-6 mb-5'>
                        <Services_box icon={<i class="fa-solid fa-chart-simple"></i>} heading="Digital Marketing" content='We deliver more than standard solutions; our expertise ensures effective and impactful marketing strategies.' />
                    </div>
                    <div className='col-xl-4 col-md-6 mb-5'>
                        <Services_box icon={<i class="fa-brands fa-shopify"></i>} heading="Shopify Development" content='We design and develop customized Shopify stores, ensuring a seamless shopping experience and effective e-commerce solutions.' />
                    </div>
                    <div className='col-xl-4 col-md-6 mb-5'>
                        <Services_box icon={<i class="fa-solid fa-arrow-up-wide-short"></i>} heading="SEO" content='We enhance your online visibility with tailored SEO strategies that improve search engine rankings and drive targeted traffic to your website.' />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services_main
