import React from 'react'

const Contactbox = ({ iconClass, title, info }) => {
    return (
        <div className='contactb_box mb-5'>
            <div className='d-flex align-items-center'>
                <div className='contact_b_icon me-4'>
                    <i class={iconClass}></i>
                </div>
                <div>
                    <h5 className='contact_b_title mt-3 pb-0 mb-2' style={{ fontWeight: "600" }}>{title}</h5>
                    <p className='contact_b_info mb-0'>{info}</p>
                </div>

            </div>
        </div>
    )
}

export default Contactbox
