import React from 'react'
import { Link } from 'react-router-dom';


const Button = ({ value, linkPath }) => {
    return (
        <div>
            <button className='main_button'><Link to={linkPath}>{value}</Link></button>
        </div>
    )
}

export default Button
