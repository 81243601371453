import React, { useState } from 'react'

function Ex_box({ icon, title, content }) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <div className={`ex_box ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <div className='icon'>
                    {icon}</div>
                <h3 className='ex_title mb-0 mt-4'>{title}</h3>
                <p className='animated_line mt-4'></p>
                <p style={{ lineHeight: '40px' }} className='mb-3'>{content}</p>
            </div>

        </div>
    )
}

export default Ex_box
