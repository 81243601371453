import React from 'react'

const Services_box = ({ heading, content, icon }) => {
    return (
        <div className='d-flex service_box'>
            <div className='sb_icon pe-4 ' style={{ fontSize: '40px', color: ' #7141b1' }} >
                {icon}
            </div>
            <div>
                <h5 className='sb-heading' style={{ marginBottom: '17px', fontWeight: '700' }}>{heading}</h5>
                <p style={{ fontSize: '15px', lineHeight: '32px' }}>{content}</p>
            </div>
        </div>
    )
}

export default Services_box
