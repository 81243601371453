import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import Heading_up from '../heading_up'
import H_section from '../h_section'
import CoPartner from '../subcomponents/coPartner'
import Footer from '../footer'
import member1 from './images/oo.jpg'
import { Link } from 'react-router-dom'
import TeamBox from '../subcomponents/teamBox'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import vision1 from './images/one.png'
import vision4 from './images/four.png'
import vision2 from './images/two.png'
import vision3 from './images/three.png'
import style1 from './images/styleleft.png'
import Companylogo from './images/company.png'
import Progressbar from '../subcomponents/progressbar'
import Trusted from '../subcomponents/trusted'

const Company = () => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once
        threshold: 0.1, // Trigger when 10% of the component is in view
    });

    useEffect(() => {
        if (inView) {
            setHasAnimated(true);
        }
    }, [inView]);

    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className='page_container'>
                <div className='page_info'>
                    <div className='container'>
                        <h2 className='page_name'>About Us</h2>
                    </div>
                </div>



            </div>
            <div className='page_content'>
                <div className='container'>
                    <div className='partners_container_company section_padding'>
                        <div className='row'>
                            <div className='left_partner col-lg-6 mt-4' style={{ overflow: 'hidden' }}>
                                <Heading_up value='// About Company' />
                                <H_section value='Trusted Partner for' value1='Digital Success' />
                                {/* <h2 style={{ fontSize: '40px', fontWeight: '700' }} className='mb-3'></h2> */}
                                <p style={{ lineHeight: '40px' }} className='mb-3'>Extreme Logics is a trusted technology partner dedicated to empowering businesses to achieve their goals through innovative software solutions. Our comprehensive services include custom software development, product design, quality assurance, and strategic consulting.

                                    .
                                    <p className='mt-4' style={{ color: 'black', fontStyle: 'italic' }}>
                                        <b>
                                            We help maintain and modernize your IT infrastructure, addressing a wide range of infrastructure-specific challenges to ensure your business operates smoothly and efficiently.
                                        </b>
                                    </p>


                                </p>

                            </div>
                            <div className='right_partner col-lg-6'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <CoPartner heading='Our Mission' image={vision1} linkPath='/aboutus' />
                                    </div>
                                    <div className='col-sm-6 pt-4'>
                                        <CoPartner heading='Our Strategy' image={vision3} linkPath='/aboutus' />
                                    </div>

                                    <div className='col-sm-6 '>
                                        <CoPartner heading='Our Vision' image={vision4} linkPath='/aboutus' />
                                    </div>
                                    <div className='col-sm-6 pt-4'>
                                        <CoPartner heading='Our Philosphy' image={vision2} linkPath='/aboutus' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* /// */}

                <section className='our_company_style_container mt-5' >
                    <div className='our_company_style ' style={{ paddingBottom: '100px', paddingTop: '130px' }}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img style={{ maxWidth: '100%', width: "100%" }} src={style1} alt='Team Image'></img>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='our_company_sright mt-4 px-3'>
                                        <Heading_up value='// Experience. Execution. Excellence.' />
                                        <H_section value='What we actually  do' />


                                        <div className='mb-4 mt-4 style_icons'>

                                            <i class="fa-brands fa-js"></i>


                                            <i class="fa-brands fa-node-js"></i>
                                            <i class="fa-brands fa-react"></i>
                                            <i class="fas fa-database"></i>
                                        </div>
                                        <h5 className='mb-3 mt-4'>Our Approach to Services
                                        </h5>
                                        <p className='p_height'>At Extreme Logics, we leverage our deep industry experience to execute projects with precision and deliver excellence in every solution. We transform challenges into opportunities through innovative approaches and rigorous execution, ensuring superior results for our clients.</p>
                                        <Link className='prototype_box_button' to='aboutus'><span className=''><i class="fa-solid fa-arrow-right-long"></i></span>Learn more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className=''>
                    <Trusted />
                </div>


                <section className='our_team_container' style={{ marginTop: '100px' }}>
                    <div className='container'>
                        <div className='our_team_sec text-center'>
                            <div>
                                <Heading_up value='// our team' />
                                <H_section value='Our Leadership Team' />

                                <p className='p_height' style={{ padding: '0px 25%' }}>Meet the dedicated professionals steering our company with vision and expertise. Their leadership drives our success and innovation.</p>
                            </div>
                            <div className='team_sec_content'>
                                <div className='d-flex flex-wrap'>

                                    <TeamBox image={member1} name='Shahzaib Mureed' destination='Co-Founder of Company' />

                                    <TeamBox image={member1} name='Hamza Shah' destination='Co-Founder of Company' />

                                    <TeamBox image={member1} name='Sarim Ali' destination='CEO of Company' />

                                    <TeamBox image={member1} name='Hammad' destination='Developer' />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='container'>
                    <section className='achievements_sec' style={{ marginTop: '100px' }}>
                        <div className='d-flex flex-wrap' ref={ref}>
                            <div className='counter_stat_container' style={{ width: '25%', textAlign: 'center' }}>
                                <h2 className="counter_stat">
                                    {hasAnimated ? <CountUp end={200} duration={2} /> : '0'}+
                                </h2>
                                <span className='counter_info'>Active Clients</span>
                            </div>
                            <div className='counter_stat_container' style={{ width: '25%', textAlign: 'center' }}>
                                <h2 className="counter_stat">
                                    {hasAnimated ? <CountUp end={30} duration={2} /> : '0'}+
                                </h2>
                                <span className='counter_info'>Projects Done</span>
                            </div>
                            <div className='counter_stat_container pt-md-0 pt-4' style={{ width: '25%', textAlign: 'center' }}>
                                <h2 className="counter_stat">
                                    {hasAnimated ? <CountUp end={10} duration={2} /> : '0'}+
                                </h2>
                                <span className='counter_info'>Team Members</span>
                            </div>
                            <div className='counter_stat_container pt-md-0 pt-4' style={{ width: '25%', textAlign: 'center' }}>
                                <h2 className="counter_stat">
                                    {hasAnimated ? <CountUp end={2} duration={2} /> : '0'}+
                                </h2>
                                <span className='counter_info'>Glorious Years</span>
                            </div>
                        </div>
                    </section>



                    <section className='skill_stat_sec' style={{ marginTop: '100px' }}>

                        <div className='row'>
                            <div className='col-lg-6 skill_stat_left'>
                                <div>
                                    <Heading_up value='// Technology Expertise' />
                                    <H_section value='Enhance and Innovate ' value1='with Cutting-Edge Tech' />

                                    <p style={{ lineHeight: '34px' }}>Showcasing our skills and progress in key technology areas, we build dedicated remote teams tailored to your unique requirements..</p>
                                </div>
                                <div className='mt-5'>
                                    <Progressbar heading='Web Development' percentage='98%' />
                                </div><div className='mt-5'>
                                    <Progressbar heading='Shopify Development' percentage='91%' />
                                </div><div className='mt-5'>
                                    <Progressbar heading='SEO' percentage='95%' />
                                </div>
                                <div className='mt-5'>
                                    <Progressbar heading='Digital Marketing' percentage='88%' />
                                </div>
                            </div>



                            <div className=' col-lg-6 pt-5 pt-lg-0 skill_stat_right d-flex justify-content-end mt-5 align-items-center'>
                                <img style={{ maxWidth: '100%', width: '500px' }} src={Companylogo}></img>

                            </div>



                        </div>
                    </section>
                </div>





            </div>
            <div style={{ marginTop: '100px' }}>
                <Footer />
            </div>
        </div >
    )
}

export default Company
