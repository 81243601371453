import React from 'react'
import { Link } from 'react-router-dom'

const CoPartner = ({ heading, image, linkPath }) => {
    return (
        <div className='copartner_box'>
            <Link to={linkPath}>
                <div className='overlay'>
                    {heading}
                </div>
                <div className='copartner_image'>
                    <img style={{ maxWidth: '100%' }} src={image} alt={heading}></img>
                </div>
            </Link>
        </div>
    )
}

export default CoPartner
