import React from 'react'
import Ex_box from './ex_box'
import Collage from './collage.png'
import Heading_up from './heading_up'
import H_section from './h_section'
export default function Partners() {
    return (
        <div>
            <div className='partner_container row'>
                <div className='left_partner col-lg-6 mt-4' style={{ overflow: 'hidden' }}>
                    <Heading_up value='// About Company' />

                    <H_section value='Trusted Partner for' value1='Digital Success' />
                    {/* <h2 style={{ fontSize: '40px', fontWeight: '700' }} className='mb-3'></h2> */}
                    <p style={{ lineHeight: '40px' }} className='mb-3'>Extreme Logics is a trusted technology partner dedicated to empowering businesses to achieve their goals through innovative software solutions. Our comprehensive services include custom software development, product design, quality assurance, and strategic consulting. </p>
                    <div className='ex_boxes mt-5 '>
                        <div className='row'>

                            <div className='col-sm-6 '>
                                <div className='wow animated fadeInLeft' style={{ animationDelay: '0.5s' }}>
                                    <Ex_box icon={<i class="fa-solid fa-medal word_color" style={{ fontSize: '45px' }}></i>} content='Over 2 years of expertise in software solutions.' title='Experience' />
                                </div>
                            </div>

                            <div className='col-sm-6 pt-4 pt-sm-0'>
                                <div className='wow animated fadeInRight' style={{ animationDelay: '0.5s' }}>
                                    <Ex_box icon={<i class="fa-solid fa-gear word_color" style={{ fontSize: '45px' }}></i>} content='Delivering efficient and timely assistance for your needs.

.' title='Quick Support' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right_partner col-lg-6'>
                    <div className='pt-5 mt-2' style={{ overflow: 'hidden', maxWidth: '100%' }}>
                        <img src={Collage}></img>
                        {/* <img src='https://wpdemo.archiwp.com/engitech/wp-content/uploads/sites/4/2020/02/image1-home1.png' ></img> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
