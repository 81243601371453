import React, { useEffect } from 'react'
import Navbar from './navbar'
import Partners from './partners'
import Prototype_main from './prototype_main'
import Services_main from './services_main'
import Banner from './banner'
import Projects from './projects'
import Our_solution from './Our_solution'
import Client from './Client'
import Footer from './footer'
import Carouselimage from '../first_craousel.png'
import Trusted from './subcomponents/trusted'
// import Carouselimage1 from '../carousel-1.png'

export default function Home() {
    useEffect(() => {
        // Access the WOW function from the window object
        if (typeof window !== 'undefined') {
            new window.WOW({
                boxClass: 'wow',        // Class name that triggers the animation
                animateClass: 'animated', // Class name for the animation
                offset: 0,              // Distance to start the animation
                mobile: true,           // Trigger animations on mobile devices
                live: true              // Act on asynchronously loaded content
            }).init();
        }
    }, []);
    return (
        <div className=''>
            <Navbar />
            <div>
                <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel" data-interval="8000" >
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" id='carousel_item_1' data-bs-interval="8000">
                            {/* <img src={Carouselimage} class="d-block w-100" alt="..."></img> */}
                            <div class="carousel-caption ">
                                <p className='caption_up'>// Full Cycle Software Development</p>
                                <h5 >
                                    <div className='d-flex'>
                                        <div className='animated fadeInRight margin_word ' style={{ animationDelay: '0.5s', marginRight: '25px' }}>From </div>
                                        <div className='animated fadeInRight margin_word' style={{ animationDelay: '0.75s', marginRight: '25px' }}>idea </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='animated fadeInRight margin_word' style={{ animationDelay: '1.25s', marginRight: '25px' }}>to</div><div className='animated fadeInRight margin_word' style={{ animationDelay: '1.5s', marginRight: '25px' }}>Product</div>
                                    </div></h5>
                                <p className='animated fadeInRight caption_down two-line' style={{ animationDelay: '3s' }}>We are software engineers with more than 2 years experience in delivering software products.</p>
                            </div>
                        </div>
                        <div class="carousel-item" id='carousel_item_2' data-bs-interval="8000">
                            {/* <img src={Carouselimage1} class="d-block w-100" alt="..."></img> */}
                            <div class="carousel-caption ">
                                <p className='caption_up'>// Only high quality services</p>


                                <h5 >
                                    <div className='d-flex'>
                                        <div className='animated fadeInDown margin_word' style={{ animationDelay: '0.5s', marginRight: '25px' }}>Shopify</div>
                                        <div className='animated fadeInDown margin_word' style={{ animationDelay: '0.75s', marginRight: '25px' }}>App</div>
                                    </div>
                                    <div className='d-flex' style={{ gap: '30px' }}>
                                        <div className='animated fadeInDown margin_word' style={{ animationDelay: '1.5s', marginRight: '25px' }}>Development</div>
                                    </div></h5>                                <p className='animated fadeInUp caption_down two-line' style={{ animationDelay: '3s' }}>We are seasoned professionals in delivering robust e-commerce solutions, with specialized expertise in Shopify development.</p>
                            </div>
                        </div>
                        <div class="carousel-item " id='carousel_item_3' data-bs-interval="8000">
                            {/* <img src={Carouselimage} class="d-block w-100" alt="..."></img> */}

                            <div class="carousel-caption ">
                                <p className='caption_up'>// We create digital Leading products</p>

                                <h5 >
                                    <div className='d-flex'>
                                        <div className='animated fadeInUp' style={{ animationDelay: '0.5s' }}>End</div>
                                        <div className='animated fadeInUp' style={{ animationDelay: '0.75s' }}>-to-</div>
                                        <div className='animated fadeInUp' style={{ animationDelay: '1s' }}>end</div>

                                    </div>
                                    <div className='d-flex'>
                                        <div className='animated fadeInUp' style={{ animationDelay: '1.5s' }}>Development</div>
                                    </div></h5>                                 <p className='animated fadeInDown caption_down two-line' style={{ animationDelay: '3s' }}>We provide end-to-end solutions, from conceptual design to full deployment and ongoing maintenance.</p>
                            </div>
                        </div>
                    </div>
                    {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button> */}
                </div>
            </div>

            <div>
                <div className=''>
                    <Trusted />
                </div>
                <div className='container mt-5'>
                    <Partners />
                </div>
                <div className='mt-5'>
                    <Prototype_main />
                </div>
                <div className=' container' style={{ marginTop: '100px' }}>
                    <Services_main />
                </div>
                <div className=' container mt-5'>
                    <Banner />
                </div>
                <div className=' container' style={{ marginTop: '100px' }}>
                    <Projects />
                </div>
                <div className='' style={{ marginTop: '100px' }}>
                    <Our_solution />
                </div>
                <div className='client_section' style={{ paddingTop: '100px' }}>
                    <Client />
                </div>

                <div style={{ marginTop: '100px' }}>
                    <Footer />
                </div>

            </div>

        </div>
    )
}
