import React, { useEffect, useState } from 'react'
import Logo from './logo.png'
import Button from './subcomponents/button'
import { Link, useLocation } from 'react-router-dom'
function Navbar() {
    const location = useLocation();
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById("navbar");
            const sticky = navbar.offsetTop;

            if (window.pageYOffset > sticky) {
                setIsSticky(true);
            }

            else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div>
            <div className='bg-dark text-light navbar_top_container'>
                <div className='container'>
                    <div className='d-flex justify-content-between align-items-center navbar-top  '>
                        <ul className='d-flex list-unstyled mb-0'>
                            <li className=''><a target="_blank" href='https://www.facebook.com/share/fKUyWTyxFfgbrEPu/?mibextid=qi2Omg' className='text-light'><i className="p-3 border-end border-start fa-brands fa-facebook-f"></i></a></li>
                            <li className=''><a href='https://www.tiktok.com/@extremelogics?_t=8pPkaEV8ArY&_r=1' className='text-light'><i className="p-3 border-end fa-brands fa-tiktok"></i></a></li>
                            <li className=''><a target="_blank" href='https://www.linkedin.com/company/extreme-logics-co/' className='text-light'><i className="p-3 border-end fa-brands fa-linkedin-in"></i></a></li>
                            <li className=''><a target='_blank' href='https://www.instagram.com/extremelogics/?igsh=bWswbjBpbGo2aHJ3' className='text-light'><i className="p-3 border-end fa-brands fa-instagram"></i></a></li>
                        </ul>
                        <div>
                            <span className='ph-navbar border-end border-start p-3'> <span className='me-2'><i class="fa-solid fa-phone"></i></span> + 92 3186769031</span>
                            <span className='gm-navbar border-end  p-3'> <span className='me-2'><i class="fa-solid fa-envelope"></i></span>
                                hello@extremelogics.co</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`bg-white ${isSticky ? "fixed-top-custom" : ""}`} id='navbar'>
                <div className='container '>
                    <nav className='navbar navbar_bottom navbar-expand-lg navbar-light d-lg-flex justify-content-between align-items-center '>

                        <div className='navbar-logo d-flex align-items-center  '>
                            <img src={Logo} alt="Logo" className="logo" style={{
                                width: '145px',       // Set the width to 150 pixels
                                height: 'auto',       // Maintain aspect ratio
                                margin: '10px 0'      // Margin of 20px on top and bottom
                            }} />

                        </div>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse nav-list" id="navbarNav">
                            <ul class="navbar-nav navbar-list">
                                <li className='px-4 nav-item'>
                                    <Link
                                        to='/'
                                        className={location.pathname === '/' ? 'active' : ''}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className='px-4 nav-item'>
                                    <Link
                                        to='/aboutus'
                                        className={location.pathname === '/aboutus' ? 'active' : ''}
                                    >
                                        Company
                                    </Link>
                                </li>
                                <li className='px-4 nav-item'>
                                    <Link
                                        to='/services'
                                        className={location.pathname === '/services' ? 'active' : ''}
                                    >
                                        Services
                                    </Link>
                                </li>
                                <li className='px-4 nav-item'>
                                    <Link
                                        to='/blogs'
                                        className={location.pathname === '/blogs' ? 'active' : ''}
                                    >
                                        Blogs
                                    </Link>
                                </li>
                                <li className='px-4 nav-item'>
                                    <Link
                                        to='/contactus'
                                        className={location.pathname === '/contactus' ? 'active' : ''}
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div style={{ fontSize: '25px' }} className='d-lg-block d-none'>
                            <Button value='Free Consultation' linkPath='/contactus' />
                        </div>


                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Navbar
