import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import Button from './subcomponents/button'
import { Link } from 'react-router-dom'

const Banner = () => {
    return (
        <div className='banner_Section_main'>
            <div className='d-flex justify-content-between align-items-center flex-column flex-lg-row '>
                <div>
                    <div className='banner_heading_up'>// We Carry More Than Just Good Coding Skills</div>
                    <h2 className='banner_main_heading'> Let's Build Your Website!</h2>
                </div>
                <div>
                    <button className='pt-4 pt-lg-0'><Link to='/contactus'>Contact Us</Link></button>
                </div>
            </div>
        </div>
    )
}

export default Banner
