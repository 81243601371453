import React from 'react'

const ProjectBox = ({ imageUrl, heading, category }) => {
    return (
        <div className='project_box'>
            <a href='/'>
                <div className='project_box_img'>
                    <img src={imageUrl} alt="Slide 1" />
                    <div className='overlay'></div>
                </div>
                <div className='content_main'>
                    <div className='project_box_content'>
                        <p className='icon_pb'><i class="fa-solid fa-arrow-right-long"></i></p>
                        <h5 className='pr_head'>{heading}</h5>
                        <span className='pr_category'>{category}</span>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ProjectBox
