import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const Progressbar = ({ heading, percentage }) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.1, // Trigger when 10% of the component is in view
    });

    // Set animation flag when the component is in view
    React.useEffect(() => {
        if (inView) {
            setHasAnimated(true);
        }
    }, [inView]);

    return (
        <div ref={ref}>
            <div className='d-flex justify-content-between align-items-center mb-1 mt-4'>
                <h6>{heading}</h6>
                <span>{percentage}</span>
            </div>
            <div>
                <div className='progress-bar blue stripes'>
                    <span style={{
                        width: hasAnimated ? percentage : '0%',
                    }}></span></div>

                {/* <div class="">
    <span style="width: 40%"></span>
  </div> */}

            </div>
        </div>
    );
}

export default Progressbar;
