import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import CarouselComponent from './subcomponents/owlcarousel'

const Projects = () => {
    return (
        <div>
            <div className='row'>
                <div className='col-lg-5'>
                    <div className='project_Sec_headingUp'><Heading_up value='// Latest Projects' /></div>
                    <div><H_section value='Introduce Our Projects' /></div>
                </div>
                <div className='col-lg-7'>
                    <p style={{ lineHeight: '32px' }}>Discover how our recent projects have delivered innovative solutions and outstanding results, showcasing our expertise and commitment to excellence.</p>
                </div>
            </div>
            <div className='mb-5 mt-5'>
                <CarouselComponent />
            </div>
        </div>
    )
}

export default Projects
