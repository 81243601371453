import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import Techbox from './subcomponents/techbox'

const Our_solution = () => {
    return (
        <div className='ourSoution_container'>
            <div className='container'>
                <div className='os_main '>
                    <div className='os_headings'>
                        <Heading_up value='// TECHNOLOGY Solutions' />
                        <div >
                            <H_section value='We Deliver Innovative' value1=' Solutions for Trusted Partnerships' />
                        </div>
                    </div>
                    <div className='os_tech_boxes mt-5 row justify-content-center'>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 pt-4'>
                            <div className='wow animated fadeInUp' style={{ animationDelay: '0.3s' }}>
                                <Techbox linkPath='/' heading='web' iconClass='fa-solid fa-laptop-code' />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 pt-4'>
                            <div className='wow animated fadeInUp' style={{ animationDelay: '0.6s' }}>
                                <Techbox linkPath='/' heading='Android' iconClass='fa-solid fa-mobile-screen' />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 pt-4'>
                            <div className='wow animated fadeInUp' style={{ animationDelay: '0.9s' }}>

                                <Techbox linkPath='/' heading='Ecommerce' iconClass='fa-solid fa-cart-shopping' />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 pt-4'>
                            <div className='wow animated fadeInUp' style={{ animationDelay: '1.2s' }}>

                                <Techbox linkPath='/' heading='Tv' iconClass='fa-solid fa-tv' />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 pt-4'>
                            <div className='wow animated fadeInUp' style={{ animationDelay: '1.5s' }}>

                                <Techbox linkPath='/' heading='iot' iconClass='fa-brands fa-ioxhost' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Our_solution
