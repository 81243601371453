import React, { useEffect, useRef } from 'react';
import ProjectBox from './projectBox';
import project1 from './images/project-1.jpg'
import project2 from './images/project-2.jpg'
import project3 from './images/project-3.jpg'
import project4 from './images/project-4.jpg'
import project5 from './images/project-5.jpg'
import project6 from './images/project-6.jpg'

const CarouselComponent = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        // Ensure jQuery and OwlCarousel are available
        if (window.jQuery && window.jQuery.fn.owlCarousel) {
            // Initialize the Owl Carousel
            window.jQuery(carouselRef.current).owlCarousel({
                center: true,
                items: 2,
                margin: 10,
                merge: true,

                loop: true,
                autoplay: true,
                autoplayTimeout: 3000,
                responsive: {
                    0: {
                        items: 1
                    },
                    1200: {
                        items: 5
                    },
                    1000: {
                        items: 4
                    },
                    800: {
                        items: 3
                    },
                    600: {
                        items: 2
                    },

                }
            });
        }
    }, []);

    return (
        <div className="owl-carousel owl-theme" ref={carouselRef}>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project1} heading='Credit System' category='Idea / Development' />
            </div>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project3} heading='Analysis of Security' category='Idea / Security' />
            </div>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project2} heading='Ecommerce Website' category='Idea / Ecommerce' />
            </div>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project4} heading='Digital Driver App' category='Idea / Development' />
            </div>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project5} heading='Software Development ' category='Idea / Technology' />
            </div>
            <div className="item" data-merge="1.5">
                <ProjectBox imageUrl={project6} heading='Digital Marketing' category='SEO / Marketing' />
            </div>

        </div>
    );
};

export default CarouselComponent;
