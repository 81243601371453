import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Contact from './components/Pages/contact';
import Home from './components/home';
import Services from './components/Pages/services';
import Company from './components/Pages/company';
import Blogs from './components/Pages/blogs';
import ScrollToTop from './components/subcomponents/scrollTop';
import AdminPage from './components/Pages/admin';
import AdminLogin from './components/Pages/AdminLogin';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (status) => {
    setIsAuthenticated(status);
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contactus' element={<Contact />} />
          <Route path='/services' element={<Services />} />
          <Route path='/aboutus' element={<Company />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route
            path='/admin'
            element={
              isAuthenticated ? (
                <AdminPage />
              ) : (
                <AdminLogin onLogin={handleLogin} />
              )
            }
          />


        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
