import React from 'react'

const PrototypeBox = ({ h_value, content, icon, count, boxIcon }) => {
    return (

        <div className='prototype_box_container border'>
            <span className='count_number'>{count}</span>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <h4 className='pb_heading'>{h_value}</h4>
                <p className='mt-3 mb-4' style={{ fontSize: '16px', lineHeight: '32px' }}>{content}</p>
                <a className='prototype_box_button' href='/'><i class="fa-solid fa-arrow-right-long"></i> learn more</a>
                <div className='pt-4 box_icon'>
                    <i class={boxIcon}></i>
                </div>
            </div>
        </div>
    )
}

export default PrototypeBox
