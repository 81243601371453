// AdminPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
    const [contacts, setContacts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('http://localhost:4000/api/extreme/getcontacts', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setContacts(response.data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchContacts();
    }, []);

    return (
        <div className='container'>

            <div className=''>
                <h1 className='text-center mt-4 mb-4'>Admin Page</h1>
                {error && <p>Error: {error}</p>}

                <table className='table table-success table-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone no</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact, index) => (
                            <tr key={index}>
                                <td>{contact?.name}</td>
                                <td>{contact?.phone}</td>
                                <td>{contact?.email}</td>
                                <td>{contact?.message}</td>
                                <td>{new Date(contact.date).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminPage;
