import React from 'react'

const Heading_up = ({ value }) => {
    return (

        <h6 className='mb-3 word_color heading_up_sec' style={{ fontWeight: 'bold', textTransform: 'uppercase' }} >{value}</h6>
    )
}

export default Heading_up
