import React from 'react'
import { Link } from 'react-router-dom'

const Blogbox = ({ image, title, link, content, category, category1, date, author }) => {
    return (
        <div className='blog_box_container'>
            <div className='blogbox_img'>
                <Link to={link}>
                    <img src={image} alt='Blog Image'></img>
                </Link>

            </div>
            <div className='p-4 pt-0 blogbox_content_sec border border-top-0'>
                <div className='blog_categories d-flex align-items-center'>
                    <span className='blog_category'>{category}</span>
                    <span className='blog_category'>{category1}</span>
                </div>
                <div className='mb-4 blog_box_info'>_{date}_{author}</div>
                <Link to={link} className='blog_box_title mb-4 d-block'>
                    <h2>{title}</h2>
                </Link>
                <p style={{ lineHeight: '30px' }}>{content}</p>

            </div>
        </div>
    )
}

export default Blogbox
