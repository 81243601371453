import React from 'react'
import Logo from './logo2.png'
import Footerbox from './subcomponents/footerbox'
import { Link } from 'react-router-dom'
import ScrollToTopButton from './subcomponents/scrollTopButton'


const Footer = () => {
    return (
        <div>
            <div className='footer_container '>
                <div className='container'>
                    <div className='footer-logo d-flex align-items-center justify-content-center'>
                        <img src={Logo} alt="Logo" className="logo" style={{
                            width: '150px',       // Set the width to 150 pixels
                            height: 'auto',       // Maintain aspect ratio
                            margin: '20px 0'      // Margin of 20px on top and bottom
                        }} />

                    </div>
                    <div className='px-5 mt-5 footer_info_boxes d-flex justify-content-between align-items-center flex-wrap'>
                        <Footerbox iconClass='fa-solid fa-envelope' title='Our Email' info='hello@extremelogics.co' />
                        <Footerbox iconClass='fa-solid fa-globe' title='Our Address' info='Gulberg Phase 2, Khanewal, Pakistan' />
                        <Footerbox iconClass='fa-solid fa-phone' title='Our Phone' info='+92 - 3186769031' />
                    </div>
                    <div className='footer_bar'>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/'>Services</Link></li>
                            <li><Link to='/'>Portflio</Link></li>
                            <li><Link to='/'>Blog</Link></li>
                            <li><Link to='/contactus'>Contact Us</Link></li>
                        </ul>
                    </div>
                    <p className='footer_copyright'>Copyright © 2024 ExtremeLogics. All Rights Reserved.</p>
                    <div className='footer_social_links'>
                        <a href='https://www.facebook.com/share/fKUyWTyxFfgbrEPu/?mibextid=qi2Omg' target='_blank'><i class="fa-brands fa-facebook-f"></i></a>
                        <a href='https://www.linkedin.com/company/extreme-logics-co/' target='_blank'><i class="fa-brands fa-linkedin-in"></i></a>
                        <a href='https://www.instagram.com/extremelogics/?igsh=bWswbjBpbGo2aHJ3' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                        <a href='https://www.tiktok.com/@extremelogics?_t=8pPkaEV8ArY&_r=1' target='_blank'><i class="fa-brands fa-tiktok"></i></a>

                    </div>
                </div>
            </div>
            <ScrollToTopButton />
        </div>
    )
}

export default Footer 