import React from 'react'
import H_section from '../h_section'
import slide_one from '../image/slide_one.svg'
import slide_two from '../image/slide_two.svg'
import slide_three from '../image/slide_three.svg'
import slide_four from '../image/slide_four.svg'
import slide_five from '../image/slide_five.svg'
import slide_six from '../image/slide_six.svg'
const Trusted = () => {
    return (
        <div className='pt-4' style={{ background: '#F8F8F8', paddingBottom: '75px' }}>
            <div className='text-center mb-4'><H_section value='Trusted By' /></div>
            <div class="slider">
                <div class="slide-track" style={{ background: '#80808017' }}>
                    <div class="slide">
                        <img src={slide_one} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_three} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_two} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_four} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_five} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_six} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_one} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_four} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_three} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_two} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_five} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_six} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_two} height="100" width="150" alt="" />
                    </div>
                    <div class="slide">
                        <img src={slide_one} height="100" width="150" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trusted
