import React from 'react'
import Heading_up from './heading_up'
import H_section from './h_section'
import PrototypeBox from './prototypebox'
import Statics_box from './statics_box'
import statistic1 from './image/statistic-1.jpg'
import statistic2 from './image/statistic-2.jpg'

const Prototype_main = () => {
    return (

        <div>
            <div className='' style={{ backgroundColor: '#221F3C', padding: '120px 0px', paddingBottom: '200px' }}>
                <div className='container'>
                    <div className='text-center '>
                        <Heading_up value='// WHY CHOOSE US' />
                        <div className='heading_section text-light'><H_section value='Turn Your Idea' value1='into Market-Ready product' /></div>
                    </div>
                </div>
                <div className='prototype_boxes mt-5' style={{ overflow: "hidden" }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-3 col-sm-6 '>
                                <div className='wow animated fadeInRight' style={{ animationDelay: '0.4s' }}>
                                    <PrototypeBox count='01' boxIcon='fa-solid fa-pen' h_value='Product Design' content='Our product design service helps you prototype, test, and validate your ideas.' />
                                </div>
                            </div>
                            <div className='col-xl-3 col-sm-6 pt-sm-0 pt-4  '>
                                <div className='wow animated fadeInRight' style={{ animationDelay: '0.8s' }}>
                                    <PrototypeBox count='02' boxIcon='fa-solid fa-laptop-code' h_value='Development' content='We provide comprehensive development services to turn your concepts into reality.' />
                                </div>
                            </div>
                            <div className='col-xl-3 col-sm-6 pt-xl-0 pt-4 '>
                                <div className='wow animated fadeInRight' style={{ animationDelay: '1.2s' }}>
                                    <PrototypeBox count='03' boxIcon='fa-solid fa-list' h_value='Data Analytics' content='Our data analytics services offer insights to drive informed business decisions.' />
                                </div>
                            </div>
                            <div className='col-xl-3 col-sm-6 pt-xl-0 pt-4 '>
                                <div className='wow animated fadeInRight' style={{ animationDelay: '1.6s' }}>
                                    <PrototypeBox count='04' boxIcon='fa-solid fa-shield' h_value='Cyber Security' content='We ensure your business is protected with robust cybersecurity solutions.' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='wow animated fadeInUp' style={{ animationDelay: '1.8s' }}>

                <div className='static_box_container container ' style={{ marginTop: "-120px", overflow: 'hidden' }}>
                    <div className='row'>
                        <div className='col-lg-6 '>
                            <Statics_box h_value='Country Wide' backgroundImage={statistic1} content='We deliver effective solutions across more than 10 countries, ensuring seamless integration and global reach...' count='10+' />
                        </div>

                        <div className='col-lg-6 pt-lg-0 pt-4'>
                            <Statics_box h_value='Happy Coustmers' backgroundImage={statistic2} content='We proudly serve over 15,000 clients with software solutions that meet their needs and drive success...' count='15K' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Prototype_main
