import React, { useEffect, useRef } from 'react';
import Project_box from './projectBox';
import Clientbox from './clientClbox';
import coustmerlogo from './images/customer.png'

const ClientCarousel = () => {
    const clientCarouselRef = useRef(null);

    useEffect(() => {
        // Ensure jQuery and OwlCarousel are available
        if (window.jQuery && window.jQuery.fn.owlCarousel) {
            // Initialize the second Owl Carousel with its own settings
            window.jQuery(clientCarouselRef.current).owlCarousel({
                items: 3,
                margin: 20,
                loop: true,
                autoplay: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    1200: {
                        items: 2
                    },
                    992: {
                        items: 1
                    }
                }
            });
        }
    }, []);

    return (
        <div className="owl-carousel owl-theme" ref={clientCarouselRef}>
            <div className="item">
                <Clientbox cImage={coustmerlogo} name="Lasani Traders" rank='Manager' content='"Working with Extreme Logics has been a game-changer for our business. Their team delivered a custom software solution that exceeded our expectations. The project was completed on time, within budget, and with exceptional attention to detail."' />
            </div>
            <div className="item">
                <Clientbox cImage={coustmerlogo} name="Gillani Group" rank='CEO' content='"Provided us with top-notch software development services. Their expertise and dedication were evident in every phase of the project, resulting in a product that perfectly fits our needs. We highly recommend their services."' />
            </div>
            <div className="item">
                <Clientbox cImage={coustmerlogo} name="Punjab Motors" rank='Owner' content='We partnered with Extreme Logics for a complex software project, and they delivered outstanding results. The team was responsive, knowledgeable, and committed to our success, making the entire process seamless and stress-free.' />
            </div>

            {/* Add more items as needed */}
        </div>
    );
};

export default ClientCarousel;
