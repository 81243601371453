import React from 'react'
import Navbar from '../navbar'
import Services_box from '../subcomponents/services_box'
import Heading_up from '../heading_up'
import H_section from '../h_section'
import Button from '../subcomponents/button'
import Client from '../Client'
import Footer from '../footer'

const Services = () => {
    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className='page_container'>
                <div className='page_info'>
                    <div className='container'>
                        <h2 className='page_name'>Services</h2>
                    </div>
                </div>
            </div>
            <div className='page_content mt-5 pt-5'>
                <div className='container'>
                    <div className='services_up_container d-flex justify-content-between align-items-center'>
                        <div>
                            <Heading_up value='// Our Services' />
                            <H_section value='Offering Comprehensive' value1='Range of IT Services' />
                        </div>
                        <div>
                            <Button value='Free Quotes' linkPath='/contactus' />
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div className='row'>
                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-laptop-code"></i>} heading="Web Development" content='Our extensive experience sets us apart in delivering exceptional web development solutions..' />
                            </div>

                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-mobile-screen"></i>} heading="Mobile Development" content='We build advanced mobile applications, ensure seamless integration, and modernize existing systems.' />
                            </div>
                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-chart-simple"></i>} heading="Digital Marketing" content='We deliver more than standard solutions; our expertise ensures effective and impactful marketing strategies.' />
                            </div>
                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-brands fa-shopify"></i>} heading="Shopify Development" content='We design and develop customized Shopify stores, ensuring a seamless shopping experience and effective e-commerce solutions.' />
                            </div>
                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-arrow-up-wide-short"></i>} heading="SEO" content='We enhance your online visibility with tailored SEO strategies that improve search engine rankings and drive targeted traffic to your website.' />
                            </div>
                            <div className='col-xl-4 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-regular fa-object-group"></i>} heading="UI/UX Design" content='With over a decade of experience, we design user-friendly interfaces that boost engagement and support business growth.' />
                            </div>
                            <div className='col-xl-6 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-people-group"></i>} heading="Didicated Team" content='Our highly skilled and dedicated development team seamlessly integrates with your operations, delivering tailored solutions to meet your specific business needs.' />
                            </div>

                            <div className='col-xl-6 col-md-6 mb-5'>
                                <Services_box icon={<i class="fa-solid fa-person-chalkboard"></i>} heading="Tech Education and Consulting" content='We provide training programs and expert consulting to equip your team with cutting-edge skills and insights, fueling innovation and growth.' />
                            </div>

                        </div>
                    </div>

                </div>
                <div className='consultaion_box_container'>
                    <div className='container'>
                        <div className='text-center '>
                            <p className='heading_up'>// Drop us a line! We are here to answer your questions 24/7</p>
                            <h2 className='heading_consultaion'>NEED A CONSULTATION?  </h2>
                            <div>
                                <Button value='Contact us' linkPath='/contactus' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='client_section' style={{ paddingTop: '100px' }}>
                <Client />
            </div>
            <div style={{ marginTop: '100px' }}>
                <Footer />
            </div>

        </div>
    )
}

export default Services
