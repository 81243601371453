import React, { useState } from 'react';
import Navbar from '../navbar';
import Blogbox from '../subcomponents/blogbox';
import bimage1 from './images/blog1.jpg'

import bimage4 from './images/blog4.jpg'
import bimage5 from './images/blog5.jpg'

import bimage2 from './images/blog2.jpg'
import bimage3 from './images/blog3.jpg'
import banner from './images/ads.png'
import Author1 from './images/author1.jpg'
import Author2 from './images/author2.png'
import Author3 from './images/author3.jpg'
import Author4 from './images/author4.png'
import Author5 from './images/author5.jpg'

import Author6 from './images/author6.png'

import Footer from '../footer';
import { Link } from 'react-router-dom';

const Blogs = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 3;

    // Sample blog data
    const blogs = [
        {
            image: bimage5,
            title: 'How to Earn Online?',
            content: 'The basic premise of search engine reputation management...',
            link: '#',
            category: 'Design',
            category1: 'Development',
            date: '22-08-2024',
            author: 'Extreme Logics'
        },
        {
            image: bimage4,
            title: 'Future of AI',
            content: 'The basic premise of search engine reputation management is to use the following three strategies to accomplish the goal of creating a completely positive first page of search engine results for a specific term…',
            link: '#',
            category: 'Technology',
            category1: 'AI',
            date: '22-08-2022',
            author: 'Extreme Logics'
        },
        {
            image: bimage1,
            title: 'Your Business with Software',
            content: 'The basic premise of search engine reputation management is to use the following three strategies to accomplish the goal of creating a completely positive first page of search engine results for a specific term…',
            link: '#',
            category: 'Start up',
            category1: 'Coding',
            date: '22-08-2020',
            author: 'Extreme Logics'
        },
        {
            image: bimage2,
            title: 'Building Data Analytics Software',
            content: 'The basic premise of search engine reputation management is to use the following three strategies to accomplish the goal of creating a completely positive first page of search engine results for a specific term…',
            link: '#',
            category: 'technology',
            category1: 'AI',
            date: '22-08-2020',
            author: 'Extreme Logics'
        },
        {
            image: bimage3,
            title: 'Plan Your Project with Your Software',
            content: 'The basic premise of search engine reputation management...',
            link: '#',
            category: 'Design',
            category1: 'Development',
            date: '22-08-2024',
            author: 'Extreme Logics'
        },
    ];

    // Calculate the indexes for slicing the blogs array
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    // Get the current blogs to display
    const currentBlogs = blogs.slice(indexOfFirstPost, indexOfLastPost);

    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <Navbar />
            <div className='page_container'>
                <div className='page_info'>
                    <div className='container'>
                        <h2 className='page_name'>Blogs</h2>
                    </div>
                </div>
                <div className='page-content' style={{ marginTop: '80px' }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div className=''>
                                    {currentBlogs.map((blog, index) => (
                                        <div key={index} className={index !== 0 ? 'mt-5' : ''}>
                                            <div className='blog_box_container'>
                                                <Blogbox
                                                    image={blog.image}
                                                    title={blog.title}
                                                    content={blog.content}
                                                    link={blog.link}
                                                    category={blog.category}
                                                    category1={blog.category1}
                                                    date={blog.date}
                                                    author={blog.author}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* Pagination controls */}
                                <div className='pagination mt-5'>
                                    {/* Previous Arrow */}
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        className='page-link'
                                        disabled={currentPage === 1}
                                    >
                                        <i class="fa-solid fa-arrow-left"></i>                                    </button>



                                    {/* Page Numbers */}
                                    {Array.from({ length: Math.ceil(blogs.length / postsPerPage) }).map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => paginate(index + 1)}
                                            className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}

                                    {/* Next Arrow */}
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        className='page-link'
                                        disabled={currentPage === Math.ceil(blogs.length / postsPerPage)}
                                    >
                                        <i class="fa-solid fa-arrow-right"></i>                                    </button>
                                </div>

                            </div>
                            <div className='col-lg-3'>
                                <div className='mt-5 pt-5'>
                                    <div className='category_stat'>
                                        <h4 className='category_stat_title sidebar_title_blogs'>Category</h4>
                                        <div className='category_statBox'>
                                            Design <span>(3)</span>
                                        </div>
                                        <div className='category_statBox'>
                                            Development <span>(4)</span>
                                        </div>
                                        <div className='category_statBox'>
                                            Shopify <span>(1)</span>
                                        </div>
                                        <div className='category_statBox'>
                                            Technology <span>(3)</span>
                                        </div>
                                    </div>



                                    <div className='authors_sec mt-5'>
                                        <h4 className='author_sec_title sidebar_title_blogs'>Instagram</h4>
                                        <div className='author_boxes_container d-flex align-items-center flex-wrap'>

                                            <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author1} alt='Author Image'></img>
                                                </Link>
                                            </div>
                                            <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author2} alt='Author Image'></img>
                                                </Link>
                                            </div>
                                            <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author3} alt='Author Image'></img>
                                                </Link>
                                            </div>
                                            <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author4} alt='Author Image'></img>
                                                </Link>
                                            </div>
                                            <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author5} alt='Author Image'></img>
                                                </Link>
                                            </div> <div className='author_box' style={{ flex: '0 0 33.33%', padding: '5px 3px' }}>
                                                <Link to='#'>
                                                    <img src={Author6} alt='Author Image'></img>
                                                </Link>
                                            </div>

                                        </div>

                                    </div>




                                    <div className='banner_sidebar mt-5'>
                                        <img className='' style={{ width: '100%' }} src={banner}></img>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ marginTop: '100px' }}>
                <Footer />
            </div>
        </div >
    );
};

export default Blogs;
